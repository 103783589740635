import { Link } from 'react-router-dom';

interface ChefOnboardingChecklistProps {
	totalRecipes: number;
	onComplete: () => void;
	totalProducts?: number;
}

export const ChefOnboardingChecklist = ({
	totalRecipes,
	onComplete,
	totalProducts = 0,
}: ChefOnboardingChecklistProps) => {
	const hasRecipe = totalRecipes > 0;
	const hasProduct = totalProducts > 0;
	const canComplete = hasRecipe && hasProduct;

	return (
		<div className="w-full max-w-3xl mx-auto p-6 bg-white rounded-2xl shadow-lg">
			<h2 className="text-2xl font-bold text-gray-800 mb-4">
				Welcome to Kitched! 👋
			</h2>
			<p className="text-gray-600 mb-6">
				Let's get you started as a creator on our platform.
			</p>

			<div className="space-y-4">
				<div
					className={`flex items-center p-4 rounded-lg ${
						hasRecipe ? 'bg-green-50' : 'bg-gray-50'
					}`}
				>
					<div
						className={`w-6 h-6 rounded-full flex items-center justify-center mr-4 ${
							hasRecipe ? 'bg-green-500 text-white' : 'bg-gray-300'
						}`}
					>
						1
					</div>
					<div className="flex-1">
						<h3 className="font-semibold text-gray-800">Connect Instagram</h3>
						<p className="text-sm text-gray-600">
							Connect your Instagram to import your recipe posts
						</p>
					</div>
					{!hasRecipe && (
						<Link
							to="/chef/profile/instagram-posts"
							className="px-4 py-2 bg-gray-200 text-gray-700 rounded-full hover:bg-gray-300 transition-colors"
						>
							Connect
						</Link>
					)}
				</div>

				<div
					className={`flex items-center p-4 rounded-lg ${
						hasRecipe ? 'bg-green-50' : 'bg-gray-50'
					}`}
				>
					<div
						className={`w-6 h-6 rounded-full flex items-center justify-center mr-4 ${
							hasRecipe ? 'bg-green-500 text-white' : 'bg-gray-300'
						}`}
					>
						2
					</div>
					<div className="flex-1">
						<h3 className="font-semibold text-gray-800">
							Upload your first recipe
						</h3>
						<p className="text-sm text-gray-600">
							Share your recipes with the community
						</p>
					</div>
					{!hasRecipe && (
						<Link
							to="/chef/profile/instagram-posts"
							className="px-4 py-2 bg-gray-200 text-gray-700 rounded-full hover:bg-gray-300 transition-colors"
						>
							Upload
						</Link>
					)}
				</div>

				<div
					className={`flex items-center p-4 rounded-lg ${
						hasProduct ? 'bg-green-50' : 'bg-gray-50'
					}`}
				>
					<div
						className={`w-6 h-6 rounded-full flex items-center justify-center mr-4 ${
							hasProduct ? 'bg-green-500 text-white' : 'bg-gray-300'
						}`}
					>
						3
					</div>
					<div className="flex-1">
						<h3 className="font-semibold text-gray-800">
							Add an affiliate product
						</h3>
						<p className="text-sm text-gray-600">
							Link your favorite cooking products to earn commission
						</p>
					</div>
					{!hasProduct && (
						<Link
							to="/chef/profile/products"
							className="px-4 py-2 bg-gray-200 text-gray-700 rounded-full hover:bg-gray-300 transition-colors"
						>
							Add
						</Link>
					)}
				</div>

				<div className="flex items-center p-4 bg-gray-50 rounded-lg">
					<div className="w-6 h-6 rounded-full bg-gray-300 flex items-center justify-center mr-4">
						4
					</div>
					<div className="flex-1">
						<h3 className="font-semibold text-gray-800">
							Add Kitched to your Instagram bio
						</h3>
						<p className="text-sm text-gray-600">
							Link your Kitched profile in your social media
						</p>
					</div>
				</div>
			</div>

			<p className="text-lg text-gray-600 mt-6 italic">
				Once you complete these steps, Amanda from our team will reach out to
				feature you in our creator spotlight! ✨
			</p>

			<button
				onClick={onComplete}
				disabled={!canComplete}
				className={`w-full mt-6 py-3 rounded-lg font-semibold transition-colors ${
					canComplete
						? 'bg-red-500 text-white hover:bg-red-600'
						: 'bg-gray-200 text-gray-500 cursor-not-allowed'
				}`}
			>
				Complete Onboarding
			</button>
		</div>
	);
};
