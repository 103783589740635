import { useFormik } from 'formik';
import { useState } from 'react';
import * as Yup from 'yup';

import arrowUpRight from '@/assets/svg/arrow-up-right.svg';
import { Loader } from '@/components/common/Loader';
import { notifyError } from '@/helper/notifications';
import { createCreatorApplication } from '@/service/api/creatorApplication';

const validationSchema = Yup.object({
	firstName: Yup.string().required('First name is required'),
	lastName: Yup.string().required('Last name is required'),
	email: Yup.string()
		.email('Invalid email address')
		.required('Email is required'),
	instagramHandle: Yup.string()
		.required('Instagram handle is required')
		.matches(/^@?[\w.]+$/, 'Invalid Instagram handle'),
	city: Yup.string().required('City is required'),
});

export const CreatorApplicationPage = () => {
	const [isSubmitting, setIsSubmitting] = useState(false);
	const [isSubmitted, setIsSubmitted] = useState(false);

	const formik = useFormik({
		initialValues: {
			firstName: '',
			lastName: '',
			email: '',
			instagramHandle: '',
			city: '',
		},
		validationSchema,
		onSubmit: async (values) => {
			setIsSubmitting(true);
			try {
				await createCreatorApplication(values);
				setIsSubmitted(true);
			} catch (error) {
				notifyError('Failed to submit application. Please try again.');
			} finally {
				setIsSubmitting(false);
			}
		},
	});

	if (isSubmitted) {
		return (
			<section className="min-w-auto max-w-[992px] h-[770px] mb-5 px-5 mx-auto flex justify-center items-center rounded-3xl">
				<div className="text-center max-w-lg">
					<h2 className="text-2xl font-semibold mb-4 text-brandRed">
						Thank you!
					</h2>
					<p className="text-lg text-gray-700">
						Thanks for applying! We'll review your application and get back to
						you by email within 24 hours.
					</p>
				</div>
			</section>
		);
	}

	return (
		<section className="min-w-auto max-w-[992px] h-[770px] mb-5 px-5 mx-auto flex justify-between rounded-3xl sm-custom:justify-center">
			<div className="w-[320px] mt-20 mx-[30px] flex flex-col">
				<div className="w-[271px] h-[92px] text-5xl text-center mx-auto">
					<h1 className="text-[52px] w-full font-semibold -mt-9 flex flex-col items-center">
						<p>Become a</p>

						<p className="text-brandRed">Kitched</p>
						{/* <img
							src={kitchedLogo}
							alt="logo"
							className="mt-3 w-[190px] h-[45px]"
						/> */}
						<p>Creator</p>
					</h1>
				</div>

				<form
					className="flex flex-col gap-[42px] w-[320px] mt-12"
					onSubmit={formik.handleSubmit}
				>
					<div className="flex flex-col gap-[16px]">
						<div className="relative">
							<input
								type="text"
								name="firstName"
								placeholder="Enter your first name"
								onChange={formik.handleChange}
								onBlur={formik.handleBlur}
								value={formik.values.firstName}
								className="w-full h-[53px] rounded-full px-6 pt-2 focus:outline-none text-[13px] font-semibold focus:ring-1 focus:ring-brandRed focus:bg-white bg-slate-50"
							/>
							<div className="absolute left-6 top-2.5 text-[9px] text-loginTextGrey">
								<label htmlFor="firstName">FIRST NAME</label>
							</div>
							<div className="text-alertRed pl-6 text-[9px]">
								{formik.touched.firstName && formik.errors.firstName && (
									<div>{formik.errors.firstName}</div>
								)}
							</div>
						</div>

						<div className="relative">
							<input
								type="text"
								name="lastName"
								placeholder="Enter your last name"
								onChange={formik.handleChange}
								onBlur={formik.handleBlur}
								value={formik.values.lastName}
								className="w-full h-[53px] rounded-full px-6 pt-2 focus:outline-none text-[13px] font-semibold focus:ring-1 focus:ring-brandRed focus:bg-white bg-slate-50"
							/>
							<div className="absolute left-6 top-2.5 text-[9px] text-loginTextGrey">
								<label htmlFor="lastName">LAST NAME</label>
							</div>
							<div className="text-alertRed pl-6 text-[9px]">
								{formik.touched.lastName && formik.errors.lastName && (
									<div>{formik.errors.lastName}</div>
								)}
							</div>
						</div>

						<div className="relative">
							<input
								type="email"
								name="email"
								placeholder="Enter your email"
								onChange={formik.handleChange}
								onBlur={formik.handleBlur}
								value={formik.values.email}
								className="w-full h-[53px] rounded-full px-6 pt-2 focus:outline-none text-[13px] font-semibold focus:ring-1 focus:ring-brandRed focus:bg-white bg-slate-50"
							/>
							<div className="absolute left-6 top-2.5 text-[9px] text-loginTextGrey">
								<label htmlFor="email">EMAIL</label>
							</div>
							<div className="text-alertRed pl-6 text-[9px]">
								{formik.touched.email && formik.errors.email && (
									<div>{formik.errors.email}</div>
								)}
							</div>
						</div>

						<div className="relative">
							<input
								type="text"
								name="instagramHandle"
								placeholder="@username"
								onChange={formik.handleChange}
								onBlur={formik.handleBlur}
								value={formik.values.instagramHandle}
								className="w-full h-[53px] rounded-full px-6 pt-2 focus:outline-none text-[13px] font-semibold focus:ring-1 focus:ring-brandRed focus:bg-white bg-slate-50"
							/>
							<div className="absolute left-6 top-2.5 text-[9px] text-loginTextGrey">
								<label htmlFor="instagramHandle">INSTAGRAM HANDLE</label>
							</div>
							<div className="text-alertRed pl-6 text-[9px]">
								{formik.touched.instagramHandle &&
									formik.errors.instagramHandle && (
										<div>{formik.errors.instagramHandle}</div>
									)}
							</div>
						</div>

						<div className="relative">
							<input
								type="text"
								name="city"
								placeholder="Enter your city"
								onChange={formik.handleChange}
								onBlur={formik.handleBlur}
								value={formik.values.city}
								className="w-full h-[53px] rounded-full px-6 pt-2 focus:outline-none text-[13px] font-semibold focus:ring-1 focus:ring-brandRed focus:bg-white bg-slate-50"
							/>
							<div className="absolute left-6 top-2.5 text-[9px] text-loginTextGrey">
								<label htmlFor="city">CITY</label>
							</div>
							<div className="text-alertRed pl-6 text-[9px]">
								{formik.touched.city && formik.errors.city && (
									<div>{formik.errors.city}</div>
								)}
							</div>
						</div>

						<button
							type="submit"
							className="w-[156px] h-[52px] rounded-full bg-brandRed text-white flex justify-center items-center mx-auto transition-all hover:text-brandRed hover:bg-white hover:ring-2 hover:ring-brandRed hover:cursor-pointer disabled:cursor-not-allowed"
							disabled={isSubmitting || !formik.isValid}
						>
							{isSubmitting ? (
								<Loader size={1.4} />
							) : (
								<>
									<span className="text-[15px] font-semibold">Apply Now</span>
									<span className="p-[4px] bg-brandRed rounded-full border-2 border-white absolute ml-36">
										<img src={arrowUpRight} alt="arrow-icon" width="24" />
									</span>
								</>
							)}
						</button>
					</div>
				</form>
			</div>
		</section>
	);
};
