import { AxiosError } from 'axios';

import { handleAxiosError } from '@/components/common/errorMessageForAxios/errorMessageForAxios';
import Api from '@/service';

const RESOURCE = 'creator-application';

export interface ICreatorApplication {
	id: number;
	firstName: string;
	lastName: string;
	email: string;
	instagramHandle: string;
	city: string;
	status: 'PENDING' | 'APPROVED' | 'REJECTED';
	createdAt: Date;
}

export interface CreateCreatorApplicationDto {
	firstName: string;
	lastName: string;
	email: string;
	instagramHandle: string;
	city: string;
}

export interface UpdateCreatorApplicationDto {
	status: 'APPROVED' | 'REJECTED';
}

// Error messages enum
export enum CREATOR_APPLICATION_ERRORS_MESSAGES {
	CREATE_ERROR = 'Failed to submit creator application',
	GET_ALL_ERROR = 'Failed to fetch creator applications',
	GET_BY_ID_ERROR = 'Failed to fetch creator application',
	UPDATE_ERROR = 'Failed to update creator application status',
	NOT_FOUND = 'Creator application not found',
}

export const createCreatorApplication = async (
	application: CreateCreatorApplicationDto,
): Promise<ICreatorApplication> => {
	try {
		const { data } = await Api.post(`/${RESOURCE}`, application);
		return data;
	} catch (error: unknown) {
		const errorMessage = handleAxiosError(
			error,
			CREATOR_APPLICATION_ERRORS_MESSAGES.CREATE_ERROR,
		);
		return Promise.reject(new Error(errorMessage));
	}
};

export const getAllCreatorApplications = async (): Promise<
	ICreatorApplication[]
> => {
	try {
		const { data } = await Api.get(`/${RESOURCE}`);
		return data;
	} catch (error: unknown) {
		console.log({ error });
		throw new Error(CREATOR_APPLICATION_ERRORS_MESSAGES.GET_ALL_ERROR);
	}
};

export const getCreatorApplicationById = async (
	id: number,
): Promise<ICreatorApplication> => {
	try {
		const { data } = await Api.get(`/${RESOURCE}/${id}`);
		return data;
	} catch (error: unknown) {
		if (error instanceof AxiosError && error.response?.status === 404) {
			throw new Error(CREATOR_APPLICATION_ERRORS_MESSAGES.NOT_FOUND);
		}
		throw new Error(CREATOR_APPLICATION_ERRORS_MESSAGES.GET_BY_ID_ERROR);
	}
};

export const updateCreatorApplicationStatus = async (
	id: number,
	updateDto: UpdateCreatorApplicationDto,
): Promise<ICreatorApplication> => {
	try {
		const { data } = await Api.put(`/${RESOURCE}/${id}`, updateDto);
		return data;
	} catch (error: unknown) {
		const errorMessage = handleAxiosError(
			error,
			CREATOR_APPLICATION_ERRORS_MESSAGES.UPDATE_ERROR,
		);
		return Promise.reject(new Error(errorMessage));
	}
};
