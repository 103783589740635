import { useEffect, useState } from 'react';

import { Loader } from '@/components/common/Loader';
import { notifyError, notifySuccess } from '@/helper/notifications';
import {
	ICreatorApplication,
	getAllCreatorApplications,
	updateCreatorApplicationStatus,
} from '@/service/api/creatorApplication';

export const ManageCreatorApplications = () => {
	const [applications, setApplications] = useState<ICreatorApplication[]>([]);
	const [loading, setLoading] = useState(true);

	useEffect(() => {
		fetchApplications();
	}, []);

	const fetchApplications = async () => {
		try {
			const data = await getAllCreatorApplications();
			setApplications(data);
		} catch (error) {
			notifyError('Failed to fetch creator applications');
		} finally {
			setLoading(false);
		}
	};

	const handleStatusUpdate = async (
		id: number,
		status: 'APPROVED' | 'REJECTED',
	) => {
		try {
			await updateCreatorApplicationStatus(id, { status });
			notifySuccess(`Application ${status.toLowerCase()} successfully`);
			// Refresh the applications list
			fetchApplications();
		} catch (error) {
			notifyError('Failed to update application status');
		}
	};

	if (loading) {
		return (
			<div className="flex justify-center items-center h-screen">
				<Loader size={2} />
			</div>
		);
	}

	return (
		<div className="container mx-auto px-4 py-8">
			<h1 className="text-2xl font-semibold mb-6">
				Manage Creator Applications
			</h1>
			<div className="bg-white shadow-md rounded-lg overflow-hidden">
				<table className="min-w-full divide-y divide-gray-200">
					<thead className="bg-gray-50">
						<tr>
							<th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
								Name
							</th>
							<th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
								Email
							</th>
							<th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
								Instagram
							</th>
							<th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
								City
							</th>
							<th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
								Status
							</th>
							<th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
								Date
							</th>
							<th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
								Actions
							</th>
						</tr>
					</thead>
					<tbody className="bg-white divide-y divide-gray-200">
						{applications.map((application) => (
							<tr key={application.id}>
								<td className="px-6 py-4 whitespace-nowrap">
									{application.firstName} {application.lastName}
								</td>
								<td className="px-6 py-4 whitespace-nowrap">
									{application.email}
								</td>
								<td className="px-6 py-4 whitespace-nowrap">
									{application.instagramHandle}
								</td>
								<td className="px-6 py-4 whitespace-nowrap">
									{application.city}
								</td>
								<td className="px-6 py-4 whitespace-nowrap">
									<span
										className={`px-2 inline-flex text-xs leading-5 font-semibold rounded-full ${
											application.status === 'PENDING'
												? 'bg-yellow-100 text-yellow-800'
												: application.status === 'APPROVED'
												? 'bg-green-100 text-green-800'
												: 'bg-red-100 text-red-800'
										}`}
									>
										{application.status}
									</span>
								</td>
								<td className="px-6 py-4 whitespace-nowrap">
									{new Date(application.createdAt).toLocaleDateString()}
								</td>
								<td className="px-6 py-4 whitespace-nowrap text-sm font-medium">
									{application.status === 'PENDING' && (
										<div className="flex space-x-2">
											<button
												onClick={() =>
													handleStatusUpdate(application.id, 'APPROVED')
												}
												className="text-green-600 hover:text-green-900"
											>
												Approve
											</button>
											<button
												onClick={() =>
													handleStatusUpdate(application.id, 'REJECTED')
												}
												className="text-red-600 hover:text-red-900"
											>
												Reject
											</button>
										</div>
									)}
								</td>
							</tr>
						))}
					</tbody>
				</table>
			</div>
		</div>
	);
};
