import { useEffect, useState } from 'react';

import { ChefOnboardingChecklist } from '@/components/home/ChefOnboardingChecklist';
import { CollectionHomeSection } from '@/components/home/CollectionsHomeSection';
import { TrendingOnKitchedSection } from '@/components/home/TrendingOnKitchedSection';
import { useAuth } from '@/context/useAuth';
import { ScrollToTop } from '@/helper/scrollToTop';
import { useGetChefRecipes } from '@/hooks/recipes/useGetChefRecipes';
import { defaultPortfolioPaginationOptions } from '@/interface/pagination.interface';
import { getProductsByChef } from '@/service/api/chef';
import { IProductLink } from '@/service/api/productLink';

export default function Home() {
	const { user } = useAuth();
	const isChef = !!user?.chef;
	const [onboardingCompleted, setOnboardingCompleted] = useState(false);
	const [chefProducts, setChefProducts] = useState<IProductLink[]>([]);

	const { totalEntities } = useGetChefRecipes(
		user?.chef?.uuid ?? '',
		defaultPortfolioPaginationOptions,
		isChef,
	);

	useEffect(() => {
		const fetchChefProducts = async () => {
			if (user?.chef?.id) {
				try {
					const products = await getProductsByChef(user.chef.id);
					setChefProducts(products);
				} catch (error) {
					console.error('Failed to fetch chef products:', error);
				}
			}
		};

		if (isChef) {
			fetchChefProducts();
		}
	}, [isChef, user?.chef?.id]);

	const shouldShowOnboarding =
		isChef &&
		!onboardingCompleted &&
		totalEntities === 0 &&
		chefProducts.length === 0;

	const handleOnboardingComplete = () => {
		setOnboardingCompleted(true);
	};

	return (
		<div className="flex flex-col items-center justify-center gap-y-1">
			<ScrollToTop />
			{/* {hasPreferences ? (
				<RecipesForYouByPreferences
					userHasSubscription={fakeUserHasSubscription}
				/>
			) : (
				<RecipesForYou userHasSubscription={fakeUserHasSubscription} />
			)} */}
			{shouldShowOnboarding ? (
				<div className="w-full py-28">
					<ChefOnboardingChecklist
						totalRecipes={totalEntities}
						totalProducts={chefProducts.length}
						onComplete={handleOnboardingComplete}
					/>
				</div>
			) : (
				<>
					<TrendingOnKitchedSection />
					<CollectionHomeSection />
				</>
			)}
		</div>
	);
}
