import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

import { trendingRecipesFiltersMenu } from '@/assets/mock/date_filter_items.mock';
import { Loader } from '@/components/common/Loader';
import { NavigationButtons } from '@/components/common/buttons/navigation-button/NavigationButtons';
import { TagsSearchMenuPopUp } from '@/components/common/pop-up/TagsPopUp';
import { RecipesNav } from '@/components/common/recipe/RecipesNav';
import { TrendingGridComponent } from '@/components/common/recipe/masonry-recipe/TrendingGridComponent';
import { useGetAllRandomRecipes } from '@/hooks/recipes/useGetAllRandomRecipes';
import { usePagination } from '@/hooks/usePagination';
import { RecipeSort } from '@/interface/filter.interface';
import { IRecipe } from '@/interface/recipe.interfaces';

interface TrendingOnKitchedSectionProps {
	userHasSubscription?: boolean;
}

export const TrendingOnKitchedSection = ({
	userHasSubscription,
}: TrendingOnKitchedSectionProps) => {
	const [open, setOpen] = useState(false);
	const onCloseModal = () => setOpen(false);
	const [tags, setTags] = useState<string>('');
	const [skip, setSkip] = useState(0);
	const take = 24;
	const location = useLocation();
	const params = new URLSearchParams(location.search);

	const isRecipeSort = (value: string | null): value is RecipeSort => {
		return Object.values(RecipeSort).includes(value as RecipeSort);
	};

	const [sort, setSort] = useState<RecipeSort>(() => {
		const sortFromUrl = params.get('sort');
		if (isRecipeSort(sortFromUrl)) {
			return sortFromUrl;
		} else {
			return RecipeSort.LATEST;
		}
	});

	useEffect(() => {
		const sortFromUrl = params.get('sort');
		if (isRecipeSort(sortFromUrl) && sortFromUrl !== sort) {
			setSort(sortFromUrl);
		}
	}, [location.search, sort]);

	const {
		allRandomRecipes,
		isLoadingAllRandomRecipes,
		errorAllRandomRecipes,
		refetchAllRandomRecipes,
		totalEntities,
	} = useGetAllRandomRecipes({ take, skip, sort, tags });

	const { totalPages, currentPage, onHandleNext, onHandlePrevious } =
		usePagination({
			take,
			skip,
			totalEntities,
			setSkip,
		});

	const onHandleSearchTag = (tag: string) => {
		setTags(tag);
	};

	useEffect(() => {
		refetchAllRandomRecipes();
	}, [skip, sort, tags]);

	if (isLoadingAllRandomRecipes)
		return (
			<div className="flex justify-center items-center mt-6">
				<Loader size={4} />
			</div>
		);

	if (errorAllRandomRecipes) return <></>;

	return (
		<div
			data-cy="trendingonkitched-section"
			className="w-full flex flex-col items-center justify-center pt-20"
			id="trending-recipes"
		>
			<div className="w-full flex flex-col gap-y-3 px-5 mb-8">
				<RecipesNav
					textTitle="Recipes"
					titleWithColor="for you"
					colorText="brandRed"
					filtersMenu={trendingRecipesFiltersMenu}
					setSort={setSort}
					setOpen={setOpen}
				/>
				{/* <TagsSearchBar
					onHandleSearchTagByQuery={onHandleSearchTag}
					sectionName={'trending'}
					setSort={setSort}
				/> */}
			</div>

			<TrendingGridComponent
				recipes={allRandomRecipes as IRecipe[]}
				userHasSubscription={userHasSubscription}
				isRefetchingMostLikedRecipes={false}
			/>

			<NavigationButtons
				currentPage={currentPage}
				totalPages={totalPages}
				handleNextClick={onHandleNext}
				handlePrevClick={onHandlePrevious}
			/>

			<TagsSearchMenuPopUp
				onHandleSearchTag={onHandleSearchTag}
				open={open}
				onCloseModal={onCloseModal}
				sectionName={'trend'}
			/>
		</div>
	);
};
